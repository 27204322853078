import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import '../css/post.css';

import Layout from "../layouts"
import Head from "../components/head"
import Sidebar from "../components/sidebar"
import RelatedPosts from "../components/related-posts"

import YoastSeoParts from '../fragments/seo';

import placeholder from "../images/default-blog.jpg";

class PostTemplate extends Component {
  render() {
    const mostRecent = this.props.data.mostRecent.nodes;
    const relatedPosts = this.props.data.relatedPosts.nodes;
    const { id, title, excerpt, link, content, date, featuredImage, categories, seo, ptPosts: { cta } } = this.props.data.wpPost;

    const shuffledPosts = relatedPosts.sort(() => 0.5 - Math.random());
    let selectedPosts = shuffledPosts.slice(0, 3);

    let categoryList = '';
    if(categories) {
      categories.nodes.map((cat, index) => {
        categoryList += index == 0 ? cat.name : ', '+cat.name;
      })
    }

    let featured = {
      url: placeholder,
      alt: 'Featured Image'
    }

    if (featuredImage && featuredImage.node) {
      const imageUrl = encodeURIComponent(featuredImage.node.sourceUrl);
      featured = {
        url: featuredImage.node.localFile.publicURL,
        alt: featuredImage.node.altText
      };
    }
    
    // console.log(featured.url);
    // console.log(featuredImage.node.sourceUrl)
    // @TODO: STEP #5: Use title and content in Gatsby.
    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />
        <div className="container blogSingle">
          <div className="grid smallGrid">
            <div className="col-sm-8 maincopy">
              <p className="blogDetails">{date} | {categoryList}</p>
              <div className="grid align-end">
                <div className="col-sm-8">
                  <h1>{title}</h1>
                </div>
                <div className="col-sm-4">
                  
                  <div className="socialBlog">
                    <span>Share This:</span>
                    <a rel="noreferrer" aria-label="social link" target="_blank" href={"https://www.facebook.com/share.php?u="+link}><FontAwesomeIcon icon={faFacebookF} /></a>
                    <a rel="noreferrer" aria-label="social link" target="_blank" href={"http://twitter.com/share?url="+link+"&count=horizontal"}><FontAwesomeIcon icon={faTwitter} /></a>
                    <a rel="noreferrer" aria-label="social link" target="_blank" href={"https://www.linkedin.com/shareArticle?mini=true&url="+link+"&title="+title+"&summary=&source=LinkedIn"}><FontAwesomeIcon icon={faLinkedinIn} /></a>
                    <a rel="noreferrer" aria-label="social link" target="_blank" href={"mailto:?subject="+title+"&body=Check%20out%20this%20article%20"+link}><FontAwesomeIcon icon={faEnvelope} /></a>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="col-sm-8 maincopy">

              <img src={featured.url} alt={featured.alt} className="flex-img mainImage" />
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
              { cta &&
                <div className="cta-container"><a className="cta" href={cta.url} target={cta.target}>{cta.title}</a></div>
              }
              <div className="socialBlog" style={{'textAlign':'right'}}>
                <span>Share This:</span>
                <a rel="noreferrer" aria-label="social link" target="_blank" href={"https://www.facebook.com/share.php?u="+link}><FontAwesomeIcon icon={faFacebookF} /></a>
                <a rel="noreferrer" aria-label="social link" target="_blank" href={"http://twitter.com/share?url="+link+"&count=horizontal"}><FontAwesomeIcon icon={faTwitter} /></a>
                <a rel="noreferrer" aria-label="social link" target="_blank" href={"https://www.linkedin.com/shareArticle?mini=true&url="+link+"&title="+title+"&summary=&source=LinkedIn"}><FontAwesomeIcon icon={faLinkedinIn} /></a>
                <a rel="noreferrer" aria-label="social link" target="_blank" href={"mailto:?subject="+title+"&body=Check%20out%20this%20article%20"+link}><FontAwesomeIcon icon={faEnvelope} /></a>
              </div>
            </div>
            <div className="col-sm-4">
              <Sidebar posts={mostRecent} />
            </div>
          </div>
        </div>
        <RelatedPosts relatedPosts={selectedPosts} />
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query ($id: String!, $relatedCategoryId: [String]) {
  wpPost(id: {eq: $id}) {
    id
    link
    title
    excerpt
    content
    ptPosts {
      cta {
        target
        title
        url
      }
    }
    seo {
      ...seoParts
    }
    date(formatString: "MMMM DD, YYYY")
    categories {
      nodes {
        name
        slug
      }
    }
    featuredImage {
      node {
        altText
        sourceUrl
        localFile {
          publicURL
        }
      }
    }
  }

  mostRecent: allWpPost (sort: {fields: date, order: DESC}, limit: 5) {
    nodes {
      id
      title
      link
      excerpt
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            publicURL
          }
        }
      }
    }
  }

  relatedPosts: allWpPost(
    sort: {fields: date, order: DESC}
    limit: 15
    filter: {categories: {nodes: {elemMatch: {id: {in: $relatedCategoryId}}}}}
  ) {
    nodes {
      id
      title
      link
      excerpt
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          id
          name
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            publicURL
          }
        }
      }
    }
  }

}
`
